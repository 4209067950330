import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import Sidebar from '../components/Sidebar'

export default function Services() {
  return (
    <Layout>
      <section>
        <div className='container'>
            <div className='row d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-8 mb-5">
                    
                    <h4>Front Door Decorative Insulated Glass Repair (Triple Glazed)</h4>

                        <p>
                            You may also have a front door decorative glass that is insulated, that is, a decorative leaded piece of glass sandwiched between 2 pieces of clear tempered glass (1 piece of clear tempered glass on the inside of the door and 1 piece on the outside), and in between these two pieces of tempered glass is the decorative glass panel (it often happens that a weedeater or other lawn equipment will throw a rock or other object) and this will shatter the outside pane of glass(in a spider web type fashion a shown in picture below).
                        </p>
                        <p>
                            Beheben Glass offers the service to repair this glass in the door so as no need to replace the whole door as this can be much more costly and time consuming.
                        </p>
                </div>

            </div>

            <div className='row mb-5 d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-4 mb-3"> 
                    
                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/Triple-Glazed-Broken1-2-e1393531027121-1-rotated.jpg'>
                            <StaticImage
                                src='../images/Triple-Glazed-Broken1-2-e1393531027121-1-rotated.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>
                    </div>

                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

                </div> 
                <div className="col-sm-12 col-lg-4"> 

                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/Triple-Glazed-Broken1-3-1-scaled.jpg'>
                            <StaticImage
                                src='../images/Triple-Glazed-Broken1-3-1-scaled.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>

                    </div>

                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

</div> 
                 
            </div>
        </div>
      </section>   
    </Layout>
  )
}
